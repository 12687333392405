<template>
  <div
    class="device-info bg-white u-flex u-row-between van-hairline--bottom"
    @click="handleToDetail"
  >
    <div class="u-m-r-6">
      <Tag :text="['', '娃', '兑', '艺'][deviceInfo.deviceType]" />
    </div>
    <div class="u-m-r-6">
      <Tag
        bg="#999999"
        :text="['', '脉', '串'][deviceInfo.communicationMethod]"
      />
    </div>
    <div class="u-flex-1 u-flex van-ellipsis u-main-color">
      <span>{{ deviceInfo.deviceCode }}</span>
      <span v-if="isOperator()">-{{ deviceInfo.sortNo + "号机" }}</span>
      <!-- 信号 -->
      <SignalIcon
        v-if="isVendor()"
        class="u-m-l-6"
        :online="deviceInfo.onlineState"
        :signal="deviceInfo.networkSignal"
      />
    </div>
    <div class="info-icons-right">
      <!-- 停售 -->
      <van-icon
        v-if="isOperator() && [0, 2].includes(deviceInfo.isSell)"
        class="u-m-l-6"
        class-prefix="iconfont"
        :name="['guzhang', '', 'tingshou'][deviceInfo.isSell]"
        size="20"
        color="#F2AE42"
      />
      <!-- 信号 -->
      <SignalIcon
        v-if="isOperator()"
        class="u-m-l-6"
        :online="deviceInfo.onlineState"
        :signal="deviceInfo.networkSignal"
      />
      <span class="u-content-color" v-if="isVendor()">{{
        ["已注册", "已激活", "已回收"][deviceInfo.activeState]
      }}</span>
      <van-icon class="u-m-l-6" name="arrow" size="18" color="#979797" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Tag from "@/components/Tag.vue";
import { useRouter } from "vue-router";
import SignalIcon from "./SignalIcon.vue";
import { isOperator, isVendor } from "@/hooks/useRole";
export default defineComponent({
  name: "DeviceInfo",
  components: {
    Tag,
    SignalIcon,
  },
  props: ["deviceInfo"],
  setup(props) {
    const router = useRouter();
    const handleToDetail = () => {
      let path = "";
      if (isOperator()) {
        path = "/device/detail/operator";
      } else {
        path = "/device/detail/vendor";
      }
      router.push({
        path,
        query: {
          deviceId: props.deviceInfo.deviceId,
        },
      });
    };
    return {
      isOperator,
      isVendor,
      handleToDetail,
    };
  },
});
</script>

<style lang="scss" scoped>
.device-info {
  padding: 13px;
}
</style>
